import { backOfficeRoles } from 'models';
import UsersManagement from 'pages/usersManagement/UsersManagement';
import { RouteProps } from 'react-router-dom';
import lazyWithRetry from 'utils/LazyWithRetry';

// Different paths of the app
type Path = {
  [key: string]: { path: string; allowedRoles?: backOfficeRoles[] };
};

const { ROLE_ADMIN, ROLE_DISPATCHER, ROLE_SUPERVISOR } = backOfficeRoles;

export const paths: Path = {
  home: { path: '/' },
  login: { path: '/login' },
  logout: { path: '/logout' },
  admin: { path: '/admin' },
  invalidUser: { path: '/invalid-user' },
  recurrentInvalidCredentials: { path: '/invalid-credentials' },
  changePassword: { path: '/change-password' },
  publicMap: { path: '/public-map/:serviceId' },
  dashboard: { path: '/admin/dashboard' },
  companyRegistration: {
    path: '/admin/company-registration',
    allowedRoles: [ROLE_ADMIN],
  },
  serviceTake: {
    path: '/admin/service-take',
  },
  currentServices: {
    path: '/admin/current-services',
  },
  myCurrentServices: {
    path: '/admin/my-services',
  },
  servicesAssignment: {
    path: '/admin/services/:id',
    allowedRoles: [ROLE_ADMIN, ROLE_DISPATCHER, ROLE_SUPERVISOR],
  },
  towFollowUpManagement: {
    path: '/admin/tow-follow-up-management',
    allowedRoles: [ROLE_ADMIN, ROLE_SUPERVISOR],
  },
  brokersManagement: {
    path: '/admin/brokers-management',
    allowedRoles: [ROLE_ADMIN, ROLE_SUPERVISOR],
  },
  massPolicyRegistration: {
    path: '/admin/mass-policy-registration',
    allowedRoles: [ROLE_ADMIN],
  },
  usersManagement: {
    path: '/admin/users-management',
    allowedRoles: [ROLE_ADMIN, ROLE_SUPERVISOR],
  },
  servicesHistory: {
    path: '/admin/services-history',
  },
  servicesHistoryDetails: {
    path: '/admin/services-history/:id/details',
  },
  checkingAccount: {
    path: '/admin/checking-account',
  },
  accountMovementsDetails: {
    path: '/admin/checking-account/:towCompanyId',
  },
  checkingAccountAddMovement: {
    path: '/admin/checking-account/:towCompanyId/add-movement',
  },
  InvoiceInquiry: {
    path: '/admin/invoice-inquiry',
  },

  InvoiceUpload: {
    path: '/admin/invoice-upload',
  },

  CompanyQuery: {
    path: '/admin/company-query',
  },

  CompanyUpdate: {
    path: '/admin/company-update/:id',
  },
};

export interface TouringRouteProps extends RouteProps {
  name: string;
}

type RouteList = TouringRouteProps[];

const Login = lazyWithRetry(() => import('pages/auth/Login'));
const Logout = lazyWithRetry(() => import('pages/auth/Logout'));
const InvalidUser = lazyWithRetry(() => import('pages/auth/InvalidUser'));
const RecurrentInvalidCredentials = lazyWithRetry(() => import('pages/auth/RecurrentInvalidCredentials'));
const ChangePassword = lazyWithRetry(() => import('pages/auth/ChangePassword'));
const Admin = lazyWithRetry(() => import('pages/admin/Admin'));

// Admin sub components

const Dashboard = lazyWithRetry(() => import('pages/dashboard/Dashboard'));
const CompanyRegistration = lazyWithRetry(() => import('pages/companyRegistration/CompanyRegistration'));
const ServiceTake = lazyWithRetry(() => import('pages/serviceTake/ServiceTake'));
const CurrentServices = lazyWithRetry(() => import('pages/currentServices/CurrentServices'));
const ServicesAssignment = lazyWithRetry(() => import('pages/servicesAssignment/ServicesAssignment'));
const ServicesHistory = lazyWithRetry(() => import('pages/servicesHistory/ServicesHistory'));
const ServiceHistoryDetails = lazyWithRetry(() => import('pages/serviceHistoryDetails/ServiceHistoryDetails'));
const CheckingAccountMovements = lazyWithRetry(() => import('pages/checkingAccountMovements/CheckingAccountMovements'));
const AccountMovementsDetails = lazyWithRetry(() => import('pages/accountMovementsDetails/AccountMovementsDetails'));
const AddMovementsCheckingAccount = lazyWithRetry(() => import('pages/addMovement/AddMovement'));

const CheckingInvoiceInquiry = lazyWithRetry(() => import('pages/checkingInvoiceInquiry/CheckingInvoiceInquiry'));

const InvoiceUpload = lazyWithRetry(() => import('pages/invoiceUpload'));
const CompanyQuery = lazyWithRetry(() => import('pages/companyQuery/CompanyQuery'));
const CompanyUpdate = lazyWithRetry(() => import('pages/companyUpdate/CompanyUpdate'));
const TowFollowUpManagement = lazyWithRetry(() => import('pages/towFollowUpManagement/TowFollowUpManagement'));
const BrokerManagement = lazyWithRetry(() => import('pages/brokerManagement/BrokersManagement'));
const MassPolicyRegistration = lazyWithRetry(() => import('pages/massPolicyRegistration/MassPolicyRegistration'));

const PublicMap = lazyWithRetry(() => import('pages/publicMap'));

const NOT_AUTH_ROUTES: RouteList = [
  {
    path: paths.publicMap.path,
    component: PublicMap,
    exact: true,
    name: 'PublicMap',
  },
  {
    path: paths.login.path,
    component: Login,
    exact: true,
    name: 'Login',
  },
  {
    path: paths.logout.path,
    component: Logout,
    exact: true,
    name: 'Logout',
  },
  {
    path: paths.invalidUser.path,
    component: InvalidUser,
    exact: true,
    name: 'InvalidUser',
  },
  {
    path: paths.recurrentInvalidCredentials.path,
    component: RecurrentInvalidCredentials,
    exact: true,
    name: 'RecurrentInvalidCredentials',
  },
  {
    path: paths.changePassword.path,
    component: ChangePassword,
    exact: true,
    name: 'ResetPassword',
  },
];

const AUTH_ROUTES = [
  {
    path: paths.admin.path,
    component: Admin,
    name: 'Admin',
  },
];

const ADMIN_SUB_ROUTES = [
  {
    path: paths.dashboard.path,
    component: Dashboard,
    name: 'Dashboard',
    exact: true,
  },
  {
    path: paths.companyRegistration.path,
    component: CompanyRegistration,
    name: 'Company Registration',
    exact: true,
  },
  {
    path: paths.serviceTake.path,
    component: ServiceTake,
    name: 'Service Take',
    exact: true,
  },
  {
    path: paths.currentServices.path,
    component: CurrentServices,
    name: 'Current Services',
    exact: true,
  },
  {
    path: paths.myCurrentServices.path,
    component: CurrentServices,
    name: 'My Current Services',
    exact: true,
  },
  {
    path: paths.servicesAssignment.path,
    component: ServicesAssignment,
    name: 'Services Assignment',
  },
  {
    path: paths.towFollowUpManagement.path,
    component: TowFollowUpManagement,
    name: 'Tow Follow Up Management',
  },
  {
    path: paths.brokersManagement.path,
    component: BrokerManagement,
    name: 'Companies Management',
  },
  {
    path: paths.massPolicyRegistration.path,
    component: MassPolicyRegistration,
    name: 'Mass Policy Registration',
  },
  {
    path: paths.usersManagement.path,
    component: UsersManagement,
    name: 'Users Management',
    exact: true,
  },
  {
    path: paths.servicesHistory.path,
    component: ServicesHistory,
    name: 'Services History',
    exact: true,
  },
  {
    path: paths.servicesHistoryDetails.path,
    component: ServiceHistoryDetails,
    name: 'Service History Details',
  },
  {
    path: paths.checkingAccount.path,
    component: CheckingAccountMovements,
    name: 'Checking Account Movements',
    exact: true,
  },
  {
    path: paths.accountMovementsDetails.path,
    component: AccountMovementsDetails,
    name: 'Account Movements Details',
    exact: true,
  },
  {
    path: paths.checkingAccountAddMovement.path,
    component: AddMovementsCheckingAccount,
    name: 'Add Movements Checking Account',
  },
  {
    path: paths.InvoiceInquiry.path,
    component: CheckingInvoiceInquiry,
    name: 'Checking Account Invoice Inquiry',
  },
  {
    path: paths.InvoiceUpload.path,
    component: InvoiceUpload,
    name: 'Invoice Upload',
  },
  {
    path: paths.CompanyQuery.path,
    component: CompanyQuery,
    name: 'Company Query and Update',
  },
  {
    path: paths.CompanyUpdate.path,
    component: CompanyUpdate,
    name: 'Company Update',
  },
];

export { ADMIN_SUB_ROUTES, AUTH_ROUTES, NOT_AUTH_ROUTES };
